/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import React from "react";

const getDataSourceFile = async (id, pageIndex) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/data_sources_file/data_sources_file_per_data_source/${id}?page=${pageIndex}`
    );
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

const getFileDataSource = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/data_sources_file/file_per_id/${id}`
    );
    //const result = await response.json();
    // Verificar si la solicitud fue exitosa
    if (response.ok) {
      //if (result.success !== false) {
      const downloadUrl = response.url; // Obtener la URL de descarga directamente desde la respuesta
      const message = "La solicitud se hizo correctamente";
      const status = true;
      //const { payload } = result;
      return { status, message, downloadUrl };
    } else {
      // La solicitud falló
      const result = await response.json();
      const message =
        result.message || "Error del servidor al descargar el archivo de fuente de datos";
      const status = false;
      return { status, message };
      //throw new Error("La solicitud no se pudo completar correctamente");
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

const getFileReportDataSource = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/data_sources_file/report_error/${id}`
    );
    //const result = await response.json();
    // Verificar si la solicitud fue exitosa
    if (response.ok) {
      //if (result.success !== false) {
      const downloadUrl = response.url; // Obtener la URL de descarga directamente desde la respuesta
      const message = "La solicitud se hizo correctamente";
      const status = true;
      //const { payload } = result;
      return { status, message, downloadUrl };
    } else {
      // La solicitud falló
      const result = await response.json();

      const message =
        result.message || "Error del servidor al descargar el archivo de fuente de datos";
      const status = false;
      return { status, message };
      //throw new Error("La solicitud no se pudo completar correctamente");
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

// Solicitar la lista delos estados de los carnets
const getStatusTypes = async (pageIndex) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/status/?page=${pageIndex}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

// llamar API para agregar un carnet a un usuario
const addCarnet = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/cards/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      return result;
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara el listado de tipos de fuentes o error si ocurre y retornar esos datos
//consultar el endpoint que me retornara el listado de tipos de fuentes o error si ocurre y retornar esos datos
const getSourceTypes = async (pageIndex) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/sources`);
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const { payload } = result;
      return { payload };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara el listado de tipos de fuentes o error si ocurre y retornar esos datos
const getDataSourceId = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/data_sources/data_source/${id}`);
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const { payload } = result;
      return { payload };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara si se creo correctamente la fuente de datos o error si ocurre y retornar esos datos
const createSourceFile = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/data_sources_file/upload_file`, {
      method: "POST", // Usa 'PUT' para actualizar un recurso existente.
      //headers: {
      //"Content-Type": "application/json",
      //},
      body: data, // Convierte el objeto 'data' a una cadena JSON y envíalo en el cuerpo de la solicitud.
    });
    const result = await response.json();
    return result;
  } catch (error) {}
};

export {
  getDataSourceFile,
  getStatusTypes,
  addCarnet,
  getFileDataSource,
  getSourceTypes,
  getDataSourceId,
  createSourceFile,
  getFileReportDataSource,
};
