import { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Button,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

export function EditRole({
  allPermissions = [],
  permissionsByRolList = [],
  handleUpdatePermissions,
  handleFieldChange,
  roleData,
}) {
  // Crear una función que genere la tabla para cada sección
  const createTablesForSections = (allPermissions, permissionsByRolList) => {
    return Object.keys(allPermissions).map((section) => {
      const sectionPermissions = allPermissions[section];

      // Extraemos los permisos comunes de esta sección
      const commonPermissions = sectionPermissions.filter((permission) =>
        permissionsByRolList.some((rolePermission) => rolePermission.id === permission.id)
      );

      return { section, permissions: sectionPermissions, commonPermissions };
    });
  };

  // Generar las tablas para cada sección
  const tablesData = createTablesForSections(allPermissions, permissionsByRolList);

  // Estado para los permisos seleccionados
  const [selectedPermissions, setSelectedPermissions] = useState(permissionsByRolList);

  // Función que maneja el cambio en los checkboxes
  const handleCheckboxChange = (permissionId) => {
    setSelectedPermissions((prev) => {
      const isChecked = prev.some((permission) => permission.id === permissionId);
      let updatedPermissions;

      if (isChecked) {
        // Si ya está marcado, lo desmarcamos
        updatedPermissions = prev.filter((permission) => permission.id !== permissionId);
      } else {
        // Si no está marcado, lo marcamos
        const permissionToAdd = Object.values(allPermissions)
          .flat() // Convertir el objeto en un array de todos los permisos
          .find((permission) => permission.id === permissionId);
        updatedPermissions = [...prev, permissionToAdd];
      }

      return updatedPermissions;
    });
  };

  return (
    <>
      <MDBox
        style={{
          marginRight: "2vw",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        <Divider />
        <Box>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <MDBox style={{ width: "95%" }}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Nombre:
              </Typography>
              <MDInput
                style={{ width: "100%", marginTop: "5px" }}
                label="Nombre"
                value={roleData.name}
                onChange={(e) => handleFieldChange("name", e.target.value)}
              />
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                style={{ marginTop: "15px" }}
              >
                Descripción:
              </Typography>
              <MDInput
                style={{ width: "100%", marginTop: "5px" }}
                label="Descripcion"
                multiline
                rows={3}
                value={roleData.description}
                onChange={(e) => handleFieldChange("description", e.target.value)}
              />
            </MDBox>
          </MDBox>
        </Box>
        <Divider variant="middle" />
        {/* Crear tablas por cada sección */}
        {tablesData.map((table) => (
          <Box key={table.section} style={{ marginLeft: "2rem" }}>
            <Typography variant="h6" style={{ textAlign: "center" }}>
              {table.section}
            </Typography>
            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell>Checkbox</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table.permissions.map((permission) => {
                    const isChecked = selectedPermissions.some(
                      (selectedPermission) => selectedPermission.id === permission.id
                    );

                    return (
                      <TableRow key={permission.id}>
                        <TableCell>{permission.action}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={isChecked}
                            onChange={() => handleCheckboxChange(permission.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}

        {/* Botón de actualizar */}
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px",
          }}
        >
          <MDButton onClick={() => handleUpdatePermissions(selectedPermissions)} color="success">
            Actualizar
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}
