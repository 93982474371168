export const RolePermisionsByRoleListService = async (id) => {
  try {
    const token = localStorage.getItem("token"); // O desde donde guardes tu token
    const user = localStorage.getItem("user_id"); // O desde donde guardes tu user
    const response = await fetch(`${process.env.REACT_APP_API_URL}/permissions/role/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        user: user,
      },
      body: JSON.stringify(),
    });
    const payload = await response.json();
    return payload;
  } catch (error) {}
};

export const RolePermisionsService = async () => {
  try {
    const token = localStorage.getItem("token"); // O desde donde guardes tu token
    const user = localStorage.getItem("user_id"); // O desde donde guardes tu user
    const response = await fetch(`${process.env.REACT_APP_API_URL}/permissions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        user: user,
      },
      body: JSON.stringify(),
    });
    const payload = await response.json();
    return payload;
  } catch (error) {}
};

export const UpdateRolePermissionsService = async (roleId, updatedPermissions) => {
  try {
    const token = localStorage.getItem("token"); // O desde donde guardes tu token
    const user = localStorage.getItem("user_id"); // O desde donde guardes tu user

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/permissions/role/update/${roleId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          user: user,
        },
        body: JSON.stringify({
          permissions: updatedPermissions, // Enviamos los permisos seleccionados
        }),
      }
    );

    const payload = await response.json();

    // Verificamos la respuesta de la API
    if (response.ok) {
      return { success: true, payload };
    } else {
      return { success: false, message: payload.message || "Error desconocido" };
    }
  } catch (error) {
    console.error("Error en la actualización de permisos:", error);
    return { success: false, message: error.message };
  }
};
