/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import {
  Box,
  Card,
  Divider,
  Icon,
  Modal,
  Pagination,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import verDetallesImg from "assets/images/ver_detalles.svg";
import PropTypes from "prop-types";
import data from "layouts/tables/data/authorsTableData";
import MDBadge from "components/MDBadge";
import DownloadIcon from "@mui/icons-material/Download";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MDButton from "components/MDButton";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { useNavigate } from "react-router-dom";

export default function ListDataSource({
  openBoard,
  dataSourceFile,
  nameSource,
  error,
  pageIndexAndPageSize,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
  handleClose,
  handleOpenNew,
  handleDownloadDataSourceFile,
  handleDownloadDataSourceFileReport,
  dataSourceId,
  loading2,
}) {
  const navigate = useNavigate();
  const handleNavigation = (id, nameFile) => {
    // Guardar datos en sessionStorage
    sessionStorage.setItem("nameFile", nameFile);
    // sessionStorage.setItem("nameSource", nameSource);
    navigate(`/Fuentes/${dataSourceId}/${id}`, { state: { nameFile, nameSource } });
  };
  console.log(dataSourceFile);
  return (
    <>
      <MDBox style={{ display: "flex", justifyContent: "flex-end", marginRight: "2vw" }}>
        <MDButton variant="gradient" color="info" onClick={handleOpenNew}>
          Agregar nueva fuente de datos
        </MDButton>
      </MDBox>
      <DataTable
        table={{
          columns: [
            { Header: "nombre de archivo", accessor: "file_name" },
            { Header: "Carnets", accessor: "carnets" },
            { Header: "Usuarios", accessor: "users" },
            { Header: "Errores", accessor: "errors" },
            { Header: "Aciertos", accessor: "success" },
            { Header: "Caracteres especiales", accessor: "special_character" },
            { Header: "Vacio o incompleto", accessor: "empty_or_incomplete" },
            { Header: "Responsable", accessor: "user_id" },
            { Header: "Creado", accessor: "created_at" },
            { Header: "Acciones", accessor: "actions" },
          ],
          rows: dataSourceFile
            ? dataSourceFile.map((data) => {
                return {
                  file_name: data.file_name,
                  carnets: data.carnets_quantity,
                  users: data.users_quantity,
                  errors: data.errors_quantity,
                  success: data.success_quantity,
                  special_character: data.special_character_quantity,
                  empty_or_incomplete: data.rows_empty_or_incomplete_quantity,
                  user_id: data.user_email,
                  created_at: new Date(data.created_at).toISOString().slice(0, 10),
                  actions: (
                    <>
                      <a
                        data-identifier={data.id}
                        data-name={data.source_name}
                        title={"Listado de carnets asociados"}
                        // onClick={handleOpenDataSourcesBoard}
                        style={{ cursor: "pointer", textDecoration: "none", color: "inherit" }}
                        onClick={() => handleNavigation(data.id, data.file_name)}
                        // href={`/Fuentes/${dataSourceId}/${data.id}`}
                      >
                        <PreviewOutlinedIcon
                          style={{
                            fontSize: "200px",
                            width: "24px",
                            height: "24px",
                            backgroundImage: "#000000",
                          }}
                        />
                      </a>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/data_sources_file/file_per_id/${data.id}`}
                        data-id={data.id}
                        onClick={handleDownloadDataSourceFile}
                        title="Descargar archivo"
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "inherit",
                          marginLeft: "8px",
                        }}
                      >
                        <DownloadIcon
                          style={{
                            fontSize: "200px",
                            width: "24px",
                            height: "24px",
                            backgroundImage: "#000000",
                          }}
                        />
                      </a>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/data_sources_file/report_error/${data.id}`}
                        data-id={data.id}
                        onClick={handleDownloadDataSourceFileReport}
                        title="Descargar reporte"
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "inherit",
                          marginLeft: "8px",
                        }}
                      >
                        <AssessmentIcon
                          style={{
                            fontSize: "200px",
                            width: "24px",
                            height: "24px",
                            backgroundImage: "#000000",
                          }}
                        />
                      </a>
                    </>
                  ),
                };
              })
            : [],
        }}
        pageIndexAndPageSize={pageIndexAndPageSize}
        canSearch={false}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
      />
      {/* {loading2 && (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )} */}
      {!dataSourceFile ? (
        <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDTypography
            style={{ left: "20px", fontSize: "20px" }}
            variant="button"
            color="secondary"
            fontWeight="regular"
          >
            No hay datos
          </MDTypography>
        </MDBox>
      ) : (
        <>
          <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              style={{ left: "20px" }}
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
            </MDTypography>
          </MDBox>
          <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            ></Pagination>
          </Stack>{" "}
        </>
      )}
    </>
  );
}
