/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import React, { useEffect, useState, useRef } from "react";
import ListCardType from "../modules/ListCardType";
import {
  createCardTypes,
  deleteCardTypes,
  getCardTypes,
  updatedCardTypes,
} from "../services/cardTypeService";
import { CardTypeEdit } from "../modules/CardTypeEdit";
import { CardTypeNew } from "../modules/CardTypeNew";
import { CardTypeDelete } from "../modules/CardTypeDelete";
import FilterController from "components/filter/controller/FilterController";

import MDAlert from "components/Alert";
import Spinner from "components/MDSpinner";
//import { useAuth } from "../../../context/index";
export function CardTypeController() {
  const [dataTypeCard, setDataTypeCard] = useState();
  const [name, setName] = useState("");
  const [error, setError] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [idCardUser, setIdCardUser] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [recoveredData, setRecoveredData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [objectExpired, setObjectExpired] = useState("");
  const [newData, setNewData] = useState({});
  const [infoDelete, setInfoDelete] = useState({});
  const [defaultData, setDefaultData] = useState({});
  const [isFormModified, setIsFormModified] = useState(false);
  const [loading, setLoading] = useState(true);
  const filterControllerRef = useRef();

  //Constantes de filtro
  const dataFilter = useState([
    {
      field: "NOMBRE",
      value: "name",
    },
    {
      field: "FECHA DE CREACIÓN",
      value: "created_at",
    },
    {
      field: "FECHA DE ACTUALIZACIÓN",
      value: "updated_at",
    },
  ]);
  const dataTable = "card_types"; //Tabla indicada

  const [isAlert, setIsAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");
  const [detailsAlert, setDetailsAlert] = useState({ typeAlert: "", message: "" });
  //const { token, login, logout } = useAuth();
  //Esperar respuesta de la función getCardTypes y validar respuesta para actualizar la tabla de listado de carnets o mostrar error
  const requestCardUsers = async (pageIndex) => {
    const result = await getCardTypes(pageIndex);

    if (result.status !== false) {
      const { data, last_page, total, from, to } = result;
      await setDataTypeCard(data);
      await setTotalPages(last_page);
      await setTotalData(total);
      await setEntriesStart(from);
      await setEntriesEnd(to);
    } else {
      setError("Error al consultar al servidor");
    }
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  //Esperar respuesta de la función createCardTypes y validar respuesta para actualizar la tabla de listado de tipos de carnet o mostrar error
  const requestCreateTypeCard = async (newTypeCard) => {
    const result = await createCardTypes(newTypeCard);

    if (result.status !== false) {
      console.log("Actualizo la lista");
      //requestCardUsers(currentPage);
      callHandleClickSubmit();
    } else {
      setError("Error al consultar al servidor");
    }
  };

  //Esperar respuesta de la función updatedCardTypes y validar respuesta para actualizar la tabla de listado de tipos de carnet o mostrar error
  const requestUpdateTypeCard = async (id, editTypeCard) => {
    const result = await updatedCardTypes(id, editTypeCard);

    if (result.status !== false) {
      console.log("Actualizo la lista");
      //requestCardUsers(currentPage);
      callHandleClickSubmit();
    } else {
      setError("Error al consultar al servidor");
    }
  };

  //Esperar respuesta de la función deleteCardTypes y validar respuesta para actualizar la tabla de listado de tipos de carnet o mostrar error
  const requestDeleteTypeCard = async (id) => {
    const result = await deleteCardTypes(id);

    if (result.status !== false) {
      console.log("Actualizo la lista");
      //requestCardUsers(currentPage);
      callHandleClickSubmit();
    } else {
      handleAlert("fail", result.message);
      // setError("Error al consultar al servidor");
    }
  };

  //Cerrar modal de editar tipo de carnet
  const handleCloseEdit = () => setOpenEdit(false);

  //Cerrar modal de crear tipo de carnet
  const handleCloseNew = () => setOpenNew(false);

  //Cerrar modal de eliminar tipo de carnet
  const handleCloseDelete = () => setOpenDelete(false);

  //Obtener valor por clave especifica del objeto
  const getFieldValueEdit = (fieldName) => {
    return editedData[fieldName];
  };

  //Obtener valor por clave especifica del objeto
  const getFieldValueNew = (fieldName) => {
    return newData[fieldName];
  };

  //Cambiar valor por clave y valor especifico del objeto
  const handleFieldChangeEdit = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
    handleChangeValid(fieldName, value);
  };

  //Cambiar valor por clave y valor especifico del objeto
  const handleFieldChangeNew = (fieldName, value) => {
    setNewData({
      ...newData,
      [fieldName]: value,
    });
    handleChangeValid(fieldName, value);
  };

  //ejecuta la funcion requestUpdateTypeCard con la información necesaria y cierra el modal de editar el tipo de carnet
  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        return;
      }
    }
    const { id, name } = editedData;
    // const expiredNumberStr = expiredNumber.toString();
    const objectTypeCard = {
      name: name,
      // expiration_time: `${expiredNumberStr} ${expiredStr}`,
    };
    await requestUpdateTypeCard(id, objectTypeCard);
    handleCloseEdit();
    //const convertedObject = convertValuesToString(editedData);
    //requestSourceEdit(editedData.id, editedData);
  };

  //ejecuta la funcion requestCreateTypeCard con la información necesaria y cierra el modal de crear el tipo de carnet
  const handleSubmitNew = (e) => {
    e.preventDefault();
    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        return;
      }
    }
    // const { name } = newData;
    // const expiredNumberStr = expiredNumber.toString();
    // const objectTypeCard = {
    //   name: name,
    //   expiration_time: `${expiredNumberStr} ${expiredStr}`,
    // };
    requestCreateTypeCard(newData);
    handleCloseNew();

    // Realiza la acción de enviar el formulario aquí
  };

  //ejecuta la funcion requestDeleteTypeCard con la información necesaria y cierra el modal de eliminar el tipo de carnet
  const handleSubmitDelete = (e) => {
    e.preventDefault();
    requestDeleteTypeCard(infoDelete.id);
    handleCloseDelete();
  };

  //Cambia el valor de currentPage a la página elegida
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  //Cambia el valor de currentPage a la página elegida
  const pageIndexAndPageSize = async (pageSize) => {
    await setPaginaSize(pageSize);
    requestCardUsers(currentPage, pageSize);
  };

  //abre el modal para crear un tipo de carnets
  const handleOpenNew = (event) => {
    event.preventDefault();
    resetFieldValid();
    setOpenNew(true);
    setIsDisabled(false);
    //const { dataset } = event.currentTarget;
    const data = {
      name: "",
    };
    setDefaultData(data);
    setNewData(data);
  };

  //abre el modal para editar el tipo de carnets
  const handleOpenEdit = (event) => {
    event.preventDefault();
    resetFieldValid();
    setIsDisabled(event.currentTarget.dataset.id === "view" ? true : false);
    const { dataset } = event.currentTarget;
    // const values = dataset.expired.split(" ");
    // if (values.length > 0) {
    //   const extractedValue = parseInt(values[0], 10); // Obtener la primera parte antes del espacio
    //   const extractedValue2 = values[1];
    //   const objectValue = {
    //     value1: extractedValue,
    //     value2: extractedValue2,
    //   };
    //   setObjectExpired(objectValue);
    // } else {
    // }
    const data = {
      id: dataset.identifier,
      name: dataset.name,
      // expiredNumber: parseInt(values[0], 10),
      // expiredStr: values[1],
    };

    setRecoveredData(data);
    setEditedData(data);
    setName(dataset.name);
    setOpenEdit(true);
  };

  //abre el modal para eliminar un tipo de carnets
  const handleClickDelete = (event) => {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.identifier,
      name: dataset.name,
    };
    setInfoDelete(data);
    setOpenDelete(true);
  };
  useEffect(() => {
    requestCardUsers(currentPage);
  }, []);

  // Comprueba si algún campo ha cambiado desde los valores iniciales(vacio o nulo)
  useEffect(() => {
    // Comprueba si algún campo ha cambiado desde los valores iniciales
    const hasFormChanged = Object.keys(editedData).some(
      (fieldName) => editedData[fieldName] !== recoveredData[fieldName]
    );

    const isAnyFieldEmpty = Object.values(editedData).some((value) => value === "");

    setIsFormModified(!isAnyFieldEmpty && hasFormChanged);
  }, [editedData]);

  // Comprueba si algún campo ha cambiado desde los valores iniciales(vacio o nulo)
  useEffect(() => {
    // Comprueba si algún campo ha cambiado desde los valores iniciales
    //const hasFormChanged = Object.keys(newData).some((fieldName) => newData[fieldName] !== "");
    const requiredFields = ["name"];
    const someFieldIsEmpty = requiredFields.some((fieldName) => !newData[fieldName]);
    setIsFormModified(someFieldIsEmpty);
  }, [newData]);

  // Validaciones --->

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    name: true,
  });

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      name: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    name: /^[a-zA-Z\s]*$/, // Solo permite letras y espacios
    // expiredNumber: /^\d*$/, // Verifica que sea un número
    // expiredStr: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    const isFieldValid = validateField(value.trim(), validationPatterns[fieldName]);

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = () => {
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit();
    }
  };

  return (
    <>
      <Spinner loading={loading}></Spinner>
      <FilterController
        dataTable={dataTable}
        data={dataTypeCard}
        dataFilter={dataFilter}
        setListData={setDataTypeCard}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
        requestData={requestCardUsers}
        setError={setError}
        setTotalData={setTotalData}
        setEntriesEnd={setEntriesEnd}
        setEntriesStart={setEntriesStart}
        setLoading={setLoading}
        ref={filterControllerRef}
      ></FilterController>
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      <ListCardType
        dataTypeCard={dataTypeCard}
        error={error}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleOpenNew={handleOpenNew}
        handleOpenEdit={handleOpenEdit}
        handleClickDelete={handleClickDelete}
      ></ListCardType>
      <CardTypeNew
        open={openNew}
        handleClose={handleCloseNew}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValueNew}
        handleFieldChange={handleFieldChangeNew}
        handleSubmit={handleSubmitNew}
        isFieldValid={isFieldValid}
      />
      <CardTypeEdit
        open={openEdit}
        dataTypeCard={dataTypeCard}
        objectExpired={objectExpired}
        handleClose={handleCloseEdit}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValueEdit}
        name={name}
        handleFieldChange={handleFieldChangeEdit}
        handleSubmit={handleSubmitEdit}
        isFieldValid={isFieldValid}
      />
      <CardTypeDelete
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        infoDelete={infoDelete}
        handleSubmitDelete={handleSubmitDelete}
      />
    </>
  );
}
