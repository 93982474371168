/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import React, { useEffect, useRef, useState } from "react";
import {
  getDataSourceFile,
  getStatusTypes,
  addCarnet,
  getFileDataSource,
  getFileReportDataSource,
  getSourceTypes,
  getDataSourceId,
  createSourceFile,
} from "../services/dataSourceFileService";
import { getCardTypesWithoutPagination } from "layouts/card_type/services/cardTypeService";
import DataSourceFilePerDataSource from "../modules/DataSourceFilePerDataSource";
//import ModalAddCarnet from "../modules/ModalAddCarnet";
import { getCardTypes } from "layouts/card_type/services/cardTypeService";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import { SourceFileNew } from "../modules/SourceFileNew";
import { MenuItem, Select } from "@mui/material";
import { useMaterialUIController } from "../../../context/index";
import FilterController from "components/filter/controller/FilterController";
import { useLocation } from "react-router-dom";

export function DataSourceFileController({ openBoard, idDataSource, onClose }) {
  const [controller, dispatch] = useMaterialUIController();
  const [dataSourceFile, setDataSourceFile] = useState();
  const [error, setError] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [openBoardCard, setOpenBoardCard] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [newData, setNewData] = useState({
    status: "",
    created: "",
    expires_in: "",
    card_user_id: idDataSource,
    card_type_id: "",
  });
  const [newDataFile, setNewDataFile] = useState({});
  const [statusTypes, setStatusTypes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [defaultData, setDefaultData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  //Constantes de filtro
  const dataFilter = useState([
    {
      field: "NOMBRE",
      value: "file_name",
    },
    {
      field: "RESPONSABLE",
      value: "user_email",
    },
    {
      field: "FECHA DE CREACIÓN",
      value: "created_at",
    },
  ]);
  const dataTable = "data_sources_file"; //Tabla indicada
  const filterControllerRef = useRef();

  const location = useLocation();
  const { name } = location.state || {};

  // Consulta los carnets del usuario seleccionado y las setea en una variable de estado
  const requestDataSourcesFile = async (id, pageIndex) => {
    const result = await getDataSourceFile(id, pageIndex);

    if (result.status !== false) {
      const { data, last_page, total, from, to } = result;
      //if (data.length !== 0) {
      //}
      await setDataSourceFile(data);
      await setTotalPages(last_page);
      await setTotalData(total);
      await setEntriesStart(from);
      await setEntriesEnd(to);
      await setLoading(false);
      await setLoading2(false);
    } else {
      await setLoading(false);
      await setLoading2(false);
      setError("Error al consultar al servidor");
    }
    await setLoading(false);
    await setLoading2(false);
  };

  // Cierra el modal que muestra los carnets del usuario elegido
  const handleClose = () => {
    onClose();
    setOpenBoardCard(false);
  };

  // Maneja la paginación para mostrar solo un rango de entradas por página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Hace la peticion
  const pageIndexAndPageSize = async (pageSize) => {
    await setPaginaSize(pageSize);
    requestCardUsers(currentPage, pageSize);
  };

  const getFieldValue = (fieldName) => {
    return newData[fieldName];
  };

  const handleOpenModalAdd = async (event) => {
    setOpenModalAdd(true);
    event.preventDefault();
    setIsDisabled(true);

    const data = {
      status: "",
      created: "",
      expires_in: "",
      card_user_id: idDataSource,
      card_type_id: "",
    };
    setDefaultData(data);

    await requestCarnetTypes(1);
    await requestCarnetStatus(1);
    setNewData(data);
  };

  const handleCloseModalAdd = () => setOpenModalAdd(false);

  // solicitar la lista de los estados de carnets
  const requestCarnetStatus = async (pageIndex) => {
    const result = await getStatusTypes(pageIndex);

    if (result.status !== false) {
      const { data, last_page, total, from, to } = result;
      await setStatusTypes(data);
    } else {
      //setErrorAlert("Error al consultar al servidor");
    }
  };

  // actualizar el objeto de carnet cuando se detecta un cambio en el formulario
  const handleFieldChange = async (fieldName, value) => {
    setNewData({
      ...newData,
      [fieldName]: value,
    });

    // Comprueba la validez del campo y luego establece la validez en el estado
    handleChangeValid(fieldName, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validacion antes de enviar
    const isFormValid = validateAllField();
    if (isFormValid === false) {
      return;
    }

    const convertedObject = convertValuesToString(newData);
    // Realiza la acción de enviar el formulario aquí
    requestAddCarnet(newData);
  };

  function convertValuesToString(objeto) {
    const convertedObject = {};

    for (const clave in objeto) {
      if (objeto.hasOwnProperty(clave)) {
        convertedObject[clave] = String(objeto[clave]);
      }
    }
    return convertedObject;
  }

  // enviar información del nuevo carnet al servicio que envia al API
  const requestAddCarnet = async (data) => {
    const result = await addCarnet(data);
    if (result.success !== false) {
      handleCloseModalAdd();
      requestDataSourcesFile(idDataSource, currentPage);
    } else {
      setError("Error al consultar al servidor");
    }
  };

  // Función para validar si algún campo de newData está vacío
  const hasEmptyFields = (data) => {
    return Object.values(data).some((value) => value === "");
  };

  const [dataSourceId, setDataSourceId] = useState("");
  useEffect(() => {
    //Obtener la url actual
    const currentUrl = window.location.href;
    // Extrae el ID de la fuente de datos de la URL actual dividiéndola por "/Fuentes/" y tomando la segunda parte del resultado
    const dataSourceId = currentUrl.split("/Fuentes/")[1];
    setDataSourceId(dataSourceId);
    // requestDataSourcesFile(dataSourceId, currentPage);
    requestDataSource(dataSourceId);
    console.log("dataSourceFile NAME", name);
    console.log("dataSourceFile ID");
  }, [currentPage]);

  useEffect(() => {
    if (openBoard === true) {
      if (hasEmptyFields(newData)) {
      } else {
        setIsDisabled(false);
      }
      if (newData.created !== "" && newData.expires_in !== "") {
        validateDate();
      }
    }
  }, [newData]);

  //Validaciones

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    card_type_id: true,
    created: true,
    expires_in: true,
    status: true,
  });

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      email: true,
      number_id: true,
      name: true,
      lastname: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    card_type_id: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    created: /^\d{4}-\d{2}-\d{2}$/, // Formato aaaa-mm-dd
    expires_in: /^\d{4}-\d{2}-\d{2}$/, // Formato aaaa-mm-dd
    status: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    const isFieldValid = validateField(value.trim(), validationPatterns[fieldName]);

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  // Valida que todos los inputs sean validos antes de enviar
  const validateAllField = () => {
    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        return false;
      }
    }
  };

  // Funcion para validar si la fecha de creacion es menor a la de expiracion
  const validateDate = () => {
    if (newData.created !== "" && newData.expires_in !== "") {
      if (newData.created > newData.expires_in) {
        const expirationDate = getFieldValueNew("expires_in");
        setNewData({
          ...newData,
          created: expirationDate,
        });
      }
    }
  };

  // Logica para crear nuevo archivo de fuente de datos --->
  const [openNew, setOpenNew] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [sourceTypes, setSourceTypes] = useState([]);
  const [carnetTypes, setCarnetTypes] = useState([]);
  const [dataSource, setDataSource] = useState({ name: "", type: "" });

  // Esperar respuesta de la función getSourceTypes y validar respuesta para listar los tipos de fuentes disponibles o mostrar error
  const requestSourceTypes = async (pageIndex) => {
    const result = await getSourceTypes(pageIndex);

    if (result.status !== false) {
      const { payload } = result;
      await setSourceTypes(payload);
    } else {
      handleAlert("fail", "No se pudieron cargar los tipos de fuentes");
    }
  };

  //Esperar respuesta de la función getCardTypes y validar respuesta para listar los tipos de carnet o mostrar error
  const requestCarnetTypes = async (pageIndex) => {
    const result = await getCardTypesWithoutPagination();

    if (result.status !== false) {
      const { payload } = result;
      await setCarnetTypes(payload);
    } else {
      handleAlert("fail", "No se pudieron cargar los tipos de carnet");
    }
  };

  const requestDataSource = async (id) => {
    const result = await getDataSourceId(id);

    if (result.status !== false) {
      const { payload } = result;
      await setDataSource({ name: payload.source_name, type: payload.source_type_name });
    } else {
      setError("Error al consultar al servidor");
    }
  };

  // Esperar respuesta de la función createSource y validar respuesta para conocer si se inserto correctamente la fuente de datos o mostrar error
  const requestSourceNew = async (data) => {
    try {
      const result = await createSourceFile(data);
      if (result.success !== false) {
        handleAlert("success", "Se uso correctamente la información de la fuente de datos");
        // await requestDataSourcesFile(dataSourceId, currentPage);
        callHandleClickSubmit();
        handleCloseNew();
        //const { message, last_page, total, from, to } = result;
      } else {
        if (result.message === "Archivo no encontrado") {
          handleAlert("fail", "Archivo no encontrado");
        } else if (result.message === "type_card not found") {
          handleAlert("fail", "El tipo de carnet no existe");
        } else {
          handleAlert("fail", "");
        }
        //setError("Error al consultar al servidor");
        setLoading2(false);
      }
    } catch (error) {
      setLoading2(false);
      handleAlert("fail", "Fallo al procesar el archivo");
    }
  };

  const handleCloseNew = () => setOpenNew(false);

  // Obtiene el valor de un campo específico del conjunto de datos nuevos.
  const getFieldValueNew = (fieldName) => {
    return newDataFile[fieldName];
  };

  //Maneja el cambio de valor de un campo en los datos nuevos y actualiza los datos nuevos según el campo y el valor especificados.
  const handleFieldChangeNew = (fieldName, value) => {
    if (fieldName === "name" && (value === "MySQL" || value === "PostgreSQL")) {
      newDataFile["port"] = "";
      newDataFile["db_host"] = "";
      newDataFile["nameDB"] = "";
      newDataFile["nameTable"] = "";
      newDataFile["db_username"] = "";
      newDataFile["db_password"] = "";
      newDataFile["colums"] = "";
      setColumData([]);
      setSelectedValues({});
      delete newDataFile["source"];
    } else if (fieldName === "name" && (value !== "PostgreSQL" || value !== "MySQL")) {
      delete newDataFile["port"];
      delete newDataFile["db_host"];
      delete newDataFile["nameDB"];
      delete newDataFile["nameTable"];
      delete newDataFile["db_username"];
      delete newDataFile["db_password"];
      delete newDataFile["colums"];
      newDataFile["source"] = "";
    }
    setNewDataFile({
      ...newDataFile,
      [fieldName]: value,
    });
  };

  // Maneja el envío de la fuente de datos para ser consumida segun su formato
  const handleSubmitNew = async (e) => {
    e.preventDefault();
    setIsAlert(false);
    setLoading(true);
    if (newDataFile.name === "CSV") {
      const formData = new FormData();
      formData.append("name", newDataFile.name);
      formData.append("source", newDataFile.source);
      formData.append("perfil", newDataFile.perfil);
      formData.append("created", newDataFile.created);
      formData.append("expires_in", newDataFile.expires_in);
      formData.append("username", newDataFile.username);
      formData.append("data_source_id", newDataFile.data_source_id);
      const file = getFieldValueNew("source").type;

      if (file === "text/csv") {
        // El archivo es un archivo CSV, puedes realizar acciones con él.
        setLoading2(true);
        await requestSourceNew(formData);
        setLoading(false);
        //handleCloseNew();
      } else {
        setIsAlert(true);
        setLoading(false);
        handleAlert("fail", "Por favor, selecciona un archivo CSV");
        // El archivo no es de tipo CSV, realiza la lógica de manejo de error.
      }
    } else if (newDataFile.name === "JSON") {
      const formData = new FormData();
      formData.append("name", newDataFile.name);
      formData.append("source", newDataFile.source);
      formData.append("perfil", newDataFile.perfil);
      formData.append("created", newDataFile.created);
      formData.append("expires_in", newDataFile.expires_in);
      formData.append("username", newDataFile.username);
      formData.append("data_source_id", newDataFile.data_source_id);
      const file = getFieldValueNew("source").type;

      if (file === "application/json") {
        // El archivo es un archivo CSV, puedes realizar acciones con él.
        let file = getFieldValueNew("source"); // Obteniendo el objeto File

        setLoading2(true);
        await requestSourceNew(formData);
        setLoading(false);
        //handleCloseNew();
      } else {
        setIsAlert(true);
        setLoading(false);
        handleAlert("fail", "Por favor, selecciona un archivo JSON");
        // El archivo no es de tipo CSV, realiza la lógica de manejo de error.
      }
    } else if (newDataFile.name === "EXCEL") {
      const formData = new FormData();
      formData.append("name", newDataFile.name);
      formData.append("source", newDataFile.source);
      formData.append("perfil", newDataFile.perfil);
      formData.append("created", newDataFile.created);
      formData.append("expires_in", newDataFile.expires_in);
      formData.append("username", newDataFile.username);
      formData.append("data_source_id", newDataFile.data_source_id);
      const file = getFieldValueNew("source").type;

      if (
        file === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file === "application/vnd.ms-excel"
      ) {
        // El archivo es un archivo CSV, puedes realizar acciones con él.
        await requestSourceNew(formData);
        setLoading(false);
        handleCloseNew();
      } else {
        setIsAlert(true);
        setLoading(false);
        handleAlert("fail", "Por favor, selecciona un archivo EXCEL");
        // El archivo no es de tipo CSV, realiza la lógica de manejo de error.
      }
    } else if (newDataFile.name === "MySQL") {
      await requestSourceNewDB(newDataFile);
      setLoading(false);
      handleCloseNew();
    } else if (newDataFile.name === "PostgreSQL") {
      await requestSourceNewDB(newDataFile);
      setLoading(false);
      handleCloseNew();
    }

    //const convertedObject = convertValuesToString(newDataFile);
    // Realiza la acción de enviar el formulario aquí
    //requestSourceNew(newDataFile);
    setLoading(false);
  };

  // Fuente de datos por base de datos -->
  const [columTableNative, setColumTableNative] = useState("");
  const [columData, setColumData] = useState([]);
  const [statusConexion, setStatusConexion] = useState(false);

  const [selectedValues, setSelectedValues] = useState({});

  const handleChange = (event, index) => {
    setNewDataFle({
      ...newDataFile,
      ["colums"]: { ...selectedValues, [index]: event.target.value },
    });
    setSelectedValues({ ...selectedValues, [index]: event.target.value });
  };

  const staticValues = ["Nombre", "Apellido", "Documento", "Correo"]; // Tus valores estáticos
  //const allOptions = ["Opción 1", "Opción 2", "Opción 3"]; // Tus opciones para el select

  const tableRows = staticValues.map((value) => {
    // Filtrar las opciones que ya han sido seleccionadas en otras filas
    const availableOptions = columData.filter(
      (option) =>
        !Object.values(selectedValues).includes(option) || selectedValues[value] === option
    );

    return {
      staticValue: value,
      select: (
        <Select
          value={selectedValues[value] || ""}
          onChange={(event) => handleChange(event, value)}
          displayEmpty
          required
        >
          <MenuItem value={""}>Seleccione...</MenuItem>
          {availableOptions.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ),
      // Tus otros valores de fila aquí...
    };
  });

  // Obtiene el valor de un campo específico del conjunto de datos de la tabla nativa de la base de datos
  const getFieldValueNewDB = (fieldName) => {
    return columTableNative[fieldName];
  };

  // Actualiza los datos de la tabla nativa de la base de datos con el nuevo valor del campo especificado.
  const handleFieldChangeNewDB = (fieldName, value) => {
    setColumTableNative({
      ...columTableNative,
      [fieldName]: value,
    });
  };

  // Maneja el establecimiento de la conexión con la base de datos.
  const handleEstablishConnection = async () => {
    setLoading(true);
    const conexionDB = {
      port: newDataFile["port"],
      nameDB: newDataFile["nameDB"],
      nameTable: newDataFile["nameTable"],
      db_username: newDataFile["db_username"],
      db_password: newDataFile["db_password"],
      db_host: newDataFile["db_host"],
    };
    // Verificar si el valor de la clave está vacío y eliminarla si es así
    // for (var clave in newDataFile) {
    //   if (newDataFile.hasOwnProperty(clave) && newDataFile[clave] === "") {
    //     delete newDataFile[clave];
    //   }
    // }
    await requestSourceNewConexionDB(newDataFile);
    setLoading(false);
  };

  // <-----

  //Abrir modal para hacer carga masiva por fuente de datos
  const handleOpenNew = async (event) => {
    setLoading(true);
    event.preventDefault();
    setIsDisabled(false);
    //const { dataset } = event.currentTarget;
    const data = {
      name: dataSource.type,
      source: "",
      perfil: "",
      created: "",
      expires_in: "",
      username: controller.user_id,
      data_source_id: dataSourceId,
    };
    setDefaultData(data);
    //await requestDataSource(dataSourceId);
    await requestSourceTypes(1);
    await requestCarnetTypes(1);
    await setNewDataFile(data);
    setOpenNew(true);
    setLoading(false);
  };

  // <----------

  const requestDownloadDataSourceFile = async (id) => {
    try {
      const result = await getFileDataSource(id);
      if (result.status !== false) {
        window.location.href = result.downloadUrl;
        handleAlert("success", "Se descargo correctamente el archivo");
      } else {
        handleAlert("fail", result.message || "Fallo al descargar el archivo");
      }
    } catch (e) {
      handleAlert("fail", e.message || "Fallo al descargar el archivo");
    }
  };

  const requestDownloadDataSourceFileReport = async (id) => {
    try {
      const result = await getFileReportDataSource(id);
      if (result.status !== false) {
        window.location.href = result.downloadUrl;
        handleAlert("success", "Se descargo correctamente el archivo");
      } else {
        handleAlert("fail", result.message || "Fallo al descargar el archivo");
      }
    } catch (e) {
      handleAlert("fail", error.message || "Fallo al descargar el archivo");
    }
  };

  const handleDownloadDataSourceFile = (e) => {
    e.preventDefault();
    const { dataset } = e.currentTarget;
    requestDownloadDataSourceFile(dataset.id);
  };

  const handleDownloadDataSourceFileReport = (e) => {
    e.preventDefault();
    const { dataset } = e.currentTarget;
    requestDownloadDataSourceFileReport(dataset.id);
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = () => {
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit();
    }
  };

  return (
    <>
      <Spinner loading={loading}></Spinner>
      {dataSourceId && (
        <FilterController
          dataTable={dataTable}
          data={dataSourceFile}
          dataFilter={dataFilter}
          setListData={setDataSourceFile}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
          requestData={requestDataSourcesFile}
          setError={setError}
          setTotalData={setTotalData}
          setEntriesEnd={setEntriesEnd}
          setEntriesStart={setEntriesStart}
          setLoading={setLoading}
          dataSourceId={dataSourceId}
          ref={filterControllerRef}
        ></FilterController>
      )}
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      <DataSourceFilePerDataSource
        openBoard={openBoardCard}
        dataSourceFile={dataSourceFile}
        nameSource={name}
        error={error}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleClose={handleClose}
        handleOpenNew={handleOpenNew}
        handleDownloadDataSourceFile={handleDownloadDataSourceFile}
        handleDownloadDataSourceFileReport={handleDownloadDataSourceFileReport}
        dataSourceId={dataSourceId}
        loading2={loading2}
      ></DataSourceFilePerDataSource>
      <SourceFileNew
        open={openNew}
        dataSource={dataSource}
        handleClose={handleCloseNew}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValueNew}
        sourceTypes={sourceTypes}
        carnetTypes={carnetTypes}
        handleFieldChange={handleFieldChangeNew}
        handleSubmit={handleSubmitNew}
        handleEstablishConnection={handleEstablishConnection}
        columTableNative={columTableNative}
        setColumTableNative={setColumTableNative}
        columData={columData}
        statusConexion={statusConexion}
        tableRows={tableRows}
        loading={loading}
      />
    </>
  );
}
