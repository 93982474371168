/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Cristia David Lemus Calderón                               ║
║ cristian.lemus@iudigital.edu.co                            ║
╚════════════════════════════════════════════════════════════╝
*/

import { useEffect, useState } from "react";
import { getCardPerId, updatedJoinCarnet } from "../services/VerifyCarnetIdService";
import VerifyById from "../modules/VerifyById";
import React from "react";

export default function VerifyCarnetIDController() {
  const [data, setData] = useState([]);
  const [urlAfterVerify, seturlAfterVerify] = useState([]);
  //let urlAfterVerify;
  const request = async (e) => {
    //se captura el id de carnet en la url
    const currentUrl = window.location.href;
    const urlAfter = currentUrl.split("/verify/")[1];
    if (urlAfter) {
      // Divide en ID y el valor después del ID
      const [id, qr] = urlAfter.split("/");
      // Llama al backend con el ID
      const result = await getCardPerId(id, qr);
      await setData(result.payload);
      await seturlAfterVerify(id);
    }
  };
  const handleSwitchActivation = async () => {
    // Llama a la función del controlador para realizar alguna acción
    const data = {
      joined: "1",
    };

    updatedJoinCarnet(urlAfterVerify, data);
  };

  useEffect(() => {
    // Obtener la URL actual
    request();
  }, []); // Se ejecutará solo una vez al montar el componente
  return (
    <>
      <VerifyById data={data} onSwitchActivate={handleSwitchActivation}></VerifyById>
    </>
  );
}
