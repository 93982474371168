/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import DataTable from "examples/Tables/DataTable";
import MDInput from "components/MDInput";
import verDetallesImg from "assets/images/ver_detalles.svg";
import React, { useState, useEffect, useRef } from "react";
import { useAsyncDebounce } from "react-table";
import MDBadge from "components/MDBadge";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Icon,
  Checkbox,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  CircularProgress,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import { date } from "yup";
import { position } from "stylis";
import { red } from "@mui/material/colors";
export default function ListCarnets({
  listCarnets,
  dataTypeCard,
  error,
  pageIndexAndPageSize,
  entriesStart,
  entriesEnd,
  totalData,
  paginaSize,
  currentPage,
  totalPages,
  handlePageChange,
  handleOpenToModal,
  handleSelect,
  handleSelectAll,
  selected,
  handleOpenValidity,
  rows,
  handleSubmitCardAccumulated,
}) {
  // const rows = listCarnets
  //   ? listCarnets.map((carnet) => {
  //       return {
  //         id: carnet.id,
  //         dni: carnet.number_id,
  //         name: `${carnet.card_user_name} ${carnet.card_user_lastname}`,
  //         created: new Date(carnet.created).toISOString().slice(0, 10),
  //         expired_in: new Date(carnet.expires_in).toISOString().slice(0, 10),
  //         status:
  //           carnet.status === "Activo" ? (
  //             <MDBadge badgeContent="Activo" size="xs" container color="success" />
  //           ) : (
  //             <MDBadge badgeContent={carnet.status} size="xs" container color="error" />
  //           ),
  //         view: (
  //           <a
  //             data-dni={carnet.number_id}
  //             data-name={carnet.card_user_name}
  //             data-lastname={carnet.card_user_lastname}
  //             data-status={carnet.status}
  //             data-created={carnet.created}
  //             data-expired={carnet.expires_in}
  //             data-type={carnet.card_type_name}
  //             data-userid={carnet.card_user_id}
  //             data-cardtypeid={carnet.card_type_id}
  //             style={{ cursor: "pointer" }}
  //             onClick={handleOpenToModal}
  //           >
  //             <FindInPageOutlinedIcon
  //               style={{
  //                 fontSize: "200px",
  //                 width: "24px",
  //                 height: "24px",
  //                 backgroundImage: "#000000",
  //               }}
  //             ></FindInPageOutlinedIcon>
  //             {/*<img src={verDetallesImg} />*/}
  //           </a>
  //         ),
  //       };
  //     })
  //   : [];

  return (
    <>
      {selected.length > 0 && (
        <div style={{ display: "flex", marginLeft: "20px" }}>
          <MDButton
            onClick={handleOpenValidity}
            style={{ marginTop: "10px" }}
            variant="outlined"
            color="info"
          >
            Cambiar vigencia de carnets
          </MDButton>
          <div>
            <MDButton
              onClick={handleSubmitCardAccumulated}
              style={{ left: "20px", marginTop: "10px" }}
              variant="outlined"
              color="info"
            >
              Descargar carnets seleccionados
            </MDButton>
          </div>
        </div>
      )}
      <DataTable
        table={{
          columns: [
            {
              Header: "Checkbox",
              id: "checkbox",
              accessor: (row) => (
                <Checkbox
                  checked={selected.indexOf(row.id) !== -1}
                  onChange={() => handleSelect(row.id)}
                />
              ),
              width: "0px",
              Header: () => (
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < rows.length}
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAll}
                />
              ),
            },
            // {
            //   Header: "ID",
            //   accessor: "id",
            //   Cell: (props) => (
            //     <div style={{ display: "none", width: "0px", overflow: "hidden" }}>
            //       {props.value}
            //     </div>
            //   ),
            //   show: false,
            // },
            { Header: "nombre", accessor: "name" },
            { Header: "documento de identidad", accessor: "dni" },
            { Header: "carnet", accessor: "card_type_name" },
            { Header: "creado", accessor: "created" },
            { Header: "vigencia", accessor: "expired_in" },
            { Header: "estado", accessor: "status" },
            { Header: "tipo de sangre", accessor: "blood_type" },
            { Header: "Acciones", accessor: "actions" },
          ],
          rows: rows,
        }}
        pageIndexAndPageSize={pageIndexAndPageSize}
        canSearch={false}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
      />
      {error ? (
        <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDTypography
            style={{ left: "20px", fontSize: "20px" }}
            variant="button"
            color="secondary"
            fontWeight="regular"
          >
            {error}
          </MDTypography>
        </MDBox>
      ) : listCarnets?.length === 0 || !listCarnets ? (
        <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDTypography
            style={{ left: "20px", fontSize: "20px" }}
            variant="button"
            color="secondary"
            fontWeight="regular"
          >
            No hay datos
          </MDTypography>
        </MDBox>
      ) : (
        <>
          <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              style={{ left: "20px" }}
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
            </MDTypography>
          </MDBox>
          <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            ></Pagination>
          </Stack>{" "}
        </>
      )}
    </>
  );
}
